import React, {useEffect, forwardRef} from 'react';
import {useParams} from 'react-router-dom';
import convertDateToShamsi from "../../utils/functions";
import styles from "../../styles/common/desktop/ApprovedReceiptDocument.module.css";
import sign from "../../assets/images/sign.png";
import useFetchInquiry from "../../hooks/useFetchInquiry";

const ApprovedReceiptDocument = forwardRef(({}, ref) => {
    const {id} = useParams();

    // Fetch Inquiry
    const {
        inquiryDetail,
        receiptsDetail,
        fetchAll
    } = useFetchInquiry(id);

    useEffect(() => {
        const fetchData = async () => {
            await fetchAll();
        };

        fetchData();
    }, []);

    function findReceiptById(id) {
        for (let i = 0; i < receiptsDetail.length; i++) {
            if (receiptsDetail[i].id === id) {
                return receiptsDetail[i];
            }
        }
        return null;
    }

    if (inquiryDetail === null || receiptsDetail.length === 0) {
        return null;
    }

    const inquiry = {
        number: inquiryDetail.inquiry.name,
        creationDate: convertDateToShamsi(inquiryDetail.inquiry.submitted_date),
        confirmationDate: convertDateToShamsi(inquiryDetail.result.date),
        paymentMethod: findReceiptById(inquiryDetail.result.approved_receipt).payment_method,
        otherCosts: findReceiptById(inquiryDetail.result.approved_receipt).additional_costs,
    };

    const user = {
        name: inquiryDetail.inquiry.fullname,
        organization: inquiryDetail.inquiry.organization,
    };

    const seller = {
        name: findReceiptById(inquiryDetail.result.approved_receipt).store.name,
        tel: findReceiptById(inquiryDetail.result.approved_receipt).store.phone_number,
        address: findReceiptById(inquiryDetail.result.approved_receipt).store.address,
    };

    let totalPrice = findReceiptById(inquiryDetail.result.approved_receipt).total_price;

    return (
        <div ref={ref} className={styles.page} dir='rtl'>
            <div className={styles.header}>
                <div className={styles.websiteTitle}>ایران‌تدارکات</div>
                <div className={styles.inquiryTitle}>رسید {inquiry.number}</div>
            </div>
            <hr className={styles.HorizontalLine}/>

            <p className={styles.subheader}>جزئیات درخواست</p>

            <table className={styles.detailsTable}>
                <thead className={styles.detailsthead}>
                <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th className={styles.detailsTableLastCol} scope="col"></th>
                </tr>
                </thead>

                <tbody>
                <tr className={styles.detailsTableRow}>
                    <td className={styles.dataField} scope='row'>نام مشتری:</td>
                    <td className={styles.dataEntry}>{user.name}</td>
                    <td className={styles.dataField}>نحوه پرداخت:</td>
                    <td className={styles.dataEntry}>{inquiry.paymentMethod}</td>
                </tr>
                <tr className={styles.detailsTableRow}>
                    <td className={styles.dataField} scope='row'>نام سازمان:</td>
                    <td className={styles.dataEntry}>{user.organization}</td>
                    <td className={styles.dataField}>نام فروشگاه:</td>
                    <td className={styles.dataEntry}>{seller.name}</td>
                </tr>
                <tr className={styles.detailsTableRow}>
                    <td className={styles.dataField} scope='row'>تاریخ ثبت:</td>
                    <td className={styles.dataEntry}>{inquiry.creationDate}</td>
                    <td className={styles.dataField}>شماره تلفن:</td>
                    <td className={styles.dataEntry}>{seller.tel}</td>
                </tr>
                <tr className={styles.detailsTableRow}>
                    <td className={styles.dataField} scope='row'>تاریخ تایید:</td>
                    <td className={styles.dataEntry}>{inquiry.confirmationDate}</td>
                    <td className={styles.dataField}>آدرس:</td>
                    <td className={styles.dataEntry}>{seller.address}</td>
                </tr>
                </tbody>
            </table>

            <p className={styles.subheader}>مشخصات پیش‌فاکتور تاییدشده</p>

            <table className={styles.productsTable}>
                <thead className={styles.thead}>
                <tr>
                    <th className={styles.thProductName} scope="col">نام کالا</th>
                    <th className={styles.thfirst} scope="col">تعداد</th>
                    <th className={styles.thfirst} scope="col">قیمت واحد</th>
                    <th className={styles.thfirst} scope="col">قیمت کل</th>
                </tr>
                </thead>
                <tbody>
                {receiptsDetail.find(receipt => receipt.id === inquiryDetail.result.approved_receipt).items.map((item) => (
                    <tr className={styles.tr} key={item.id}>
                        <td className={styles.td}
                            scope='row'>{item.product.main_category} | {item.product.sub_category} | {item.product.name}</td>
                        <td className={styles.td}>{item.quantity} {item.unit}</td>
                        <td className={styles.td}>{item.unit_price} ریال</td>
                        <td className={styles.td}>{item.total_price} ریال</td>
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr>
                    <th className={styles.tableOtherCosts} scope="row" colSpan={2}></th>
                    <th className={styles.tableOtherCosts} scope="row">سایر هزینه‌ها:</th>
                    <th className={styles.tableOtherCosts}>{inquiry.otherCosts} ریال</th>
                </tr>
                <tr>
                    <th className={styles.tableTotalCost} scope="row" colSpan={2}></th>
                    <th className={styles.tableTotalCost} scope="row">جمع کل</th>
                    <th className={styles.tableTotalCost}>{totalPrice} ریال</th>
                </tr>
                </tfoot>
            </table>

            <div>
                <p className={styles.result}>خرید کالاهای مورد نظر از فروشگاه <span
                    className={styles.sellerName}>{seller.name}</span> در سامانه‌ی ایران‌تدارکات تایید شد.</p>
            </div>

            <div className={styles.invoiceFooter}>
                <div className={styles.receiptFooter}>
                    <div className={styles.signature}>
                        <img src={sign} className={styles.signatureImage} alt="Signature"/>
                        <p>نام و نام خانوادگی</p>
                    </div>
                </div>

                <hr className={styles.HorizontalLine}/>
                <div className={styles.websiteAddress}>
                    www.irantadarokat.com
                </div>
            </div>
        </div>
    );
});

export default ApprovedReceiptDocument;
